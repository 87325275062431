<template>
  <main class="comment-detail">
    <p class="comment-text">{{ comment.text }}</p>
    <div class="comment-meta">
      <span class="comment-likes">{{ comment.likes }}</span>
      <span class="comment-replies">{{ comment.children.length }} replies</span>
    </div>
  </main>
</template>

<script>
export default {
  name: "CommentDetail",
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Comment container styling */
.comment-detail {
  background-color: #f9f9f9;
  padding: 15px 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

/* Hover effect for better interaction */
.comment-detail:hover {
  background-color: #f7f7f7;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

/* Main comment content */
.comment-text {
  font-size: 1rem;
  color: #444;
  margin-bottom: 10px;
}

/* Meta information about likes and replies */
.comment-meta {
  display: flex;
  gap: 15px;
  font-size: 0.9rem;
  color: #888;
}

/* Insert emoji via pseudo-element for likes */
.comment-likes::before {
  content: "👍";
  margin-right: 5px;
}

/* Insert emoji via pseudo-element for replies */
.comment-replies::before {
  content: "💬";
  margin-right: 5px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .comment-detail {
    padding: 15px;
  }
}
</style>
