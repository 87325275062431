<template>
  <div>
    <!-- New Header -->
    <header class="site-header">
      <div class="header-content">
        <div class="logo-title">
          <a href="https://forum.breakupbuddy.app" class="logo-link">
            <!-- Logo space -->
            <img src="./1.png" alt="Company Logo" class="logo" />
            <!-- Title space -->
            <span class="company-title">Breakup Buddy</span>
          </a>
        </div>
        <!-- Download Link -->
        <div class="download-link">
          <button class="download-button" @click="downloadApp">Download</button>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="home-container">
      <!-- Page Title -->
      <h1 class="page-title">Recent Posts</h1>

      <!-- Content (Posts) -->
      <div v-if="posts.length === 0" class="no-posts-message">
        <p>No posts available at the moment.</p>
      </div>

      <section v-else class="posts-list">
        <article
          v-for="post in posts"
          :key="post.publicUUID"
          class="post-preview"
        >
          <router-link
            :to="`/posts/${post.publicUUID}/${post.slug}`"
            class="router-link"
            :aria-label="`${post.title} - ${post.text}`"
          >
            <div class="post-content">
              <div class="post-text-block">
                <h2 class="post-title">{{ post.title }}</h2>
                <p class="post-excerpt">
                  {{ post.text.substring(0, 200) }}
                  {{ post.text.length > 200 ? "..." : "" }}
                </p>
              </div>
              <div class="post-meta">
                <span class="post-likes">{{ post.likes }}</span>
                <span class="post-comments">
                  {{ post.comments_count }} comments
                </span>
              </div>
            </div>
          </router-link>
        </article>
      </section>
    </main>
  </div>
</template>

<script>
import "../styles/navbarstyles.css";
// Import useHead from @vueuse/head
import { useHead } from "@vueuse/head";
import config from "@/config";

export default {
  name: "HomeView",
  data() {
    return {
      posts: [],
    };
  },
  methods: {
    downloadApp() {
      // Implement the logic to download the app
      // For example, redirect to a download page or start the download
      // window.location.href = "/path/to/your/app/download";
      // Or open in a new tab
      window.open(
        "https://apps.apple.com/us/app/breakup-buddy-heal-heartbreak/id1667956640",
        "_blank"
      );
    },
  },
  created() {
    this.$axios
      .get(`${config.apiBaseUrl}/posts`)
      .then((response) => {
        this.posts = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  // Add setup() function to set static meta tags
  setup() {
    useHead({
      title: "Breakup Buddy Forum - Home",
      meta: [
        {
          name: "description",
          content:
            "Welcome to the Breakup Buddy Forum. Share your breakup experiences and find support in a supportive healing community.",
        },
        {
          property: "og:title",
          content: "Breakup Buddy Forum - Home",
        },
        {
          property: "og:description",
          content:
            "Welcome to the Breakup Buddy Forum. Share your breakup experiences and find support in a supportive healing community.",
        },
        {
          property: "og:url",
          content: window.location.href,
        },
      ],
    });
  },
};
</script>

<style scoped>
/* Full width container that centers content */
.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Page title */
.page-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

/* No posts available message */
.no-posts-message {
  text-align: center;
  font-size: 1.2rem;
  color: #777;
  margin-top: 50px;
}

/* Posts List Container: Vertical Scroller */
.posts-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Post preview card */
.post-preview {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 20px;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Hover effect for post */
.post-preview:hover {
  background-color: #f9f9f9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transform: translateY(-3px);
}

/* Thumbnail and title row */
.post-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Block for title and excerpt */
.post-text-block {
  max-width: 75%;
}

/* Post title */
.post-title {
  font-size: 1.5rem;
  margin: 0;
  color: #1f1f1f;
  font-weight: bold;
}

/* Post text (excerpt) */
.post-excerpt {
  font-size: 1rem;
  color: #666;
  margin: 10px 0 0;
  line-height: 1.5;
}

/* Post meta information */
.post-meta {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9rem;
  color: #777;
}

.post-meta span {
  margin-right: 10px;
}

/* Likes and comment count displayed prominently */
.post-likes,
.post-comments {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.post-likes::before {
  content: "👍";
}

.post-comments::before {
  content: "💬";
}

/* Router link covers entire card */
.router-link {
  text-decoration: none;
  display: block;
  color: inherit;
}

/* Mobile responsiveness for header and posts list */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .download-button {
    margin-top: 10px;
  }

  .company-title {
    font-size: 1.4rem;
  }

  .post-text-block {
    max-width: 100%;
  }

  .post-preview {
    padding: 15px;
  }

  .post-title {
    font-size: 1.3rem;
  }
}
</style>
