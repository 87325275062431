import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueGtag from "vue-gtag";
// Import createHead from @vueuse/head
import { createHead } from "@vueuse/head";

const app = createApp(App);

app.config.productionTip = false;

app.use(VueGtag, {
  config: { id: "G-90KR81NXGN" },
});

// Add axios to the global properties
app.config.globalProperties.$axios = axios;

// Use the router
app.use(router);

// Use @vueuse/head for managing meta information
const head = createHead();
app.use(head);

// Mount the app
app.mount("#app");
