<template>
  <!-- New Header -->
  <header class="site-header">
    <div class="header-content">
      <a href="https://forum.breakupbuddy.app" class="logo-link">
        <!-- Logo space -->
        <img src="./1.png" alt="Company Logo" class="logo" />
        <!-- Title space -->
        <span class="company-title">Breakup Buddy</span>
      </a>
      <!-- Download Link -->
      <div class="download-link">
        <button class="download-button" @click="downloadApp">Download</button>
      </div>
    </div>
  </header>

  <main class="comment-view-container" v-if="comment">
    <!-- Display the current comment details -->
    <CommentDetail :comment="comment" />
    <!-- Display child comments -->
    <ChildComments :comments="comment.children" />
  </main>
  <div v-else>
    <p>Loading comment...</p>
  </div>
</template>

<script>
import "../styles/navbarstyles.css";
import config from "@/config";
import { useHead } from "@vueuse/head";
import CommentDetail from "../components/CommentDetail.vue";
import ChildComments from "../components/ChildComments.vue";

export default {
  name: "CommentView",
  components: {
    CommentDetail,
    ChildComments,
  },
  data() {
    return {
      comment: null,
      error: null,
    };
  },
  methods: {
    downloadApp() {
      window.open(
        "https://apps.apple.com/us/app/breakup-buddy-heal-heartbreak/id1667956640",
        "_blank"
      );
    },
    fetchComment() {
      const { comment_public_id, comment_slug } = this.$route.params;
      this.comment = null;
      this.$axios
        .get(
          `${config.apiBaseUrl}/comments/${comment_public_id}/${
            comment_slug || ""
          }`
        )
        .then((response) => {
          this.comment = response.data;
          console.log("Fetched comment:", this.comment);

          if (this.comment && this.comment.id) {
            // Set meta tags after comment is loaded
            this.setMetaTags();
          } else {
            console.error(
              "Comment data is missing 'id' property:",
              this.comment
            );
            this.error = "Invalid comment data received.";
          }
        })
        .catch((error) => {
          console.error("Error fetching comment:", error);
          this.error = "Failed to load comment.";
        });
    },
    setMetaTags() {
      if (this.comment) {
        useHead({
          title: `Comment by ${this.comment.posterUsername}`,
          meta: [
            {
              name: "description",
              content: this.comment.text.slice(0, 160),
              key: "description",
            },
            {
              property: "og:title",
              content: `Comment by ${this.comment.posterUsername}`,
              key: "og:title",
            },
            {
              property: "og:description",
              content: this.comment.text.slice(0, 160),
              key: "og:description",
            },
            {
              property: "og:url",
              content: window.location.href,
              key: "og:url",
            },
          ],
        });
      }
    },
  },
  created() {
    this.fetchComment();
  },
  watch: {
    "$route.params.comment_public_id": "fetchComment",
    "$route.params.comment_slug": "fetchComment",
  },
};
</script>

<style scoped>
/* Main container with more side padding for narrower content */
.comment-view-container {
  max-width: 750px; /* Reduced the max-width to make main content narrower */
  margin: 0 auto;
  padding: 20px 40px; /* Adjusted padding: 20px for top/bottom, 40px for sides */
}
</style>
