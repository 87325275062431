<template>
  <div class="child-comments" v-if="comments && comments.length">
    <h3 class="comments-title">Replies</h3>
    <section
      v-for="comment in comments"
      :key="comment.publicUUID"
      class="comment"
    >
      <div class="comment-content">
        <router-link
          :to="`/comments/${comment.publicUUID}/${comment.slug}`"
          class="router-link"
          :aria-label="`${comment.text}`"
        >
          <p class="comment-text">{{ comment.text }}</p>
          <div class="comment-meta">
            <span class="comment-likes">{{ comment.likes }}</span>
            <span class="comment-replies"
              >{{ comment.children.length }} replies</span
            >
          </div>
        </router-link>
      </div>
      <!-- Recursively render child comments if they exist -->
      <ChildComments :comments="comment.children" />
    </section>
  </div>
</template>

<script>
export default {
  name: "ChildComments",
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
  components: {
    ChildComments: null, // Placeholder for recursive reference
  },
  beforeCreate() {
    // Register the component recursively
    if (!this.$options.components.ChildComments) {
      this.$options.components.ChildComments = this.constructor;
    }
  },
};
</script>

<style scoped>
/* Container for child comments */
.child-comments {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 2px solid #eee;
  margin-bottom: 20px;
}

/* Individual comment block */
.comment {
  background-color: #fff;
  padding: 15px 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

/* Hover effect for better interaction */
.comment:hover {
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  transform: translateY(-2px);
}

/* Main content of the comment */
.comment-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Comment text */
.comment-text {
  font-size: 1rem;
  color: #444;
}

/* Comment meta (likes and replies) */
.comment-meta {
  display: flex;
  gap: 15px;
  font-size: 0.9rem;
  color: #888;
}

/* Emoji for likes using pseudo-element */
.comment-likes::before {
  content: "👍";
  margin-right: 5px;
}

/* Emoji for replies using pseudo-element */
.comment-replies::before {
  content: "💬";
  margin-right: 5px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .comment {
    padding: 15px;
  }
}
</style>
