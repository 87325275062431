<template>
  <div>
    <div v-if="loading" class="loading">
      <p>Loading preview...</p>
    </div>
    <div v-else-if="error" class="error">
      <p>{{ error }}</p>
    </div>
    <div
      v-else-if="preview.title || preview.image || linkUrl"
      class="link-preview"
    >
      <a
        :href="linkUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="thumbnail-link"
      >
        <div v-if="preview.image" class="thumbnail">
          <img
            :src="preview.image"
            :alt="preview.title || 'Link Preview Image'"
          />
          <div v-if="preview.video" class="play-button">
            <i class="fas fa-play"></i>
          </div>
        </div>
      </a>
      <div v-if="preview.title || preview.description" class="link-info">
        <h4>{{ preview.title || "No Title Available" }}</h4>
        <p>{{ preview.description || "No Description Available" }}</p>
        <a :href="linkUrl" target="_blank" rel="noopener noreferrer"
          >Visit Link</a
        >
      </div>
    </div>
    <div v-else class="no-preview">
      <p>No preview available for this link.</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

export default {
  name: "LinkPreviewComponent",
  props: {
    linkUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      preview: {},
      loading: false,
      error: "",
    };
  },
  mounted() {
    this.fetchLinkPreview();
  },
  methods: {
    async fetchLinkPreview() {
      if (!this.linkUrl) {
        this.error = "No URL provided.";
        return;
      }

      this.loading = true;
      this.error = "";

      try {
        const response = await axios.get(`${config.apiBaseUrl}/link-preview`, {
          params: { url: this.linkUrl },
          timeout: 5000, // Optional: Set a timeout for the request
        });

        if (response.data.error) {
          this.error = response.data.error;
        } else {
          this.preview = response.data;
        }
      } catch (err) {
        console.error("Error fetching link preview:", err);
        this.error = "Failed to fetch link preview.";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.link-preview {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Changed to stretch for full width */
  padding: 10px; /* Reduced padding from 20px to 10px */
  border: 1px solid #ddd;
  border-radius: 8px; /* Slightly reduced border-radius */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Adjusted shadow for subtleness */
  max-width: 600px;
  margin: 10px auto; /* Reduced margin */
  background-color: #fff; /* Ensure background is white for cleanliness */
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
}

.link-preview:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.thumbnail-link {
  text-decoration: none; /* Remove underline in case */
}

.thumbnail {
  position: relative;
  width: 100%;
  height: 100%; /* Increased height for better image display */
  overflow: hidden;
  border-radius: 8px 8px 0 0; /* Consistent border-radius */
  cursor: pointer; /* Indicate that it's clickable */
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease; /* Smooth zoom on hover */
}

.thumbnail:hover img {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #fff;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Darker background for better visibility */
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Slight darkening on hover */
}

.link-info {
  padding: 10px; /* Reduced padding from 20px to 10px */
  text-align: left; /* Align text to the left for better readability */
}

.link-info h4 {
  font-size: 20px; /* Slightly increased font size for prominence */
  font-weight: bold;
  margin-bottom: 8px; /* Reduced margin */
  color: #333; /* Darker text for better contrast */
}

.link-info p {
  font-size: 14px;
  color: #555; /* Darker description text */
  margin-bottom: 12px; /* Reduced margin */
}

.link-info a {
  font-size: 16px;
  color: #1a73e8; /* Updated link color for better visibility */
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.link-info a:hover {
  color: #0c5ed6; /* Darker shade on hover */
}

.no-preview {
  text-align: center;
  padding: 10px; /* Reduced padding */
  color: #999;
}

.loading,
.error {
  text-align: center;
  padding: 10px; /* Reduced padding */
  color: #ff0000; /* Red color for errors */
}

.loading p {
  color: #333;
}
</style>
