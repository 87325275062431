<template>
  <!-- New Header -->
  <header class="site-header">
    <div class="header-content">
      <a href="https://forum.breakupbuddy.app" class="logo-link">
        <!-- Logo space -->
        <img src="./1.png" alt="Company Logo" class="logo" />
        <!-- Title space -->
        <span class="company-title">Breakup Buddy</span>
      </a>
      <!-- Download Link -->
      <div class="download-link">
        <button class="download-button" @click="downloadApp">Download</button>
      </div>
    </div>
  </header>

  <div class="post-view-container" v-if="post">
    <!-- Post Content -->
    <div class="post-content">
      <div>
        <h1 class="post-title">{{ post.title }}</h1>
        <div>
          <LinkPreview
            v-if="post.link && post.link.length >= 2"
            :linkUrl="post.link"
          />
        </div>
        <p class="post-text">{{ post.text }}</p>
      </div>
      <div class="post-tags">
        <span v-for="tag in post.tags" class="tag-item" :key="tag">{{
          tag
        }}</span>
      </div>
      <div class="post-meta">
        <!-- Remove Emoji here; rely on CSS to add emoji -->
        <span class="post-likes">{{ post.likes }}</span>
      </div>
    </div>

    <!-- Comments Section -->
    <h2 class="comments-title">Comments:</h2>

    <div v-if="!comments || comments.length === 0" class="no-comments">
      <p>No comments yet. Be the first to comment!</p>
    </div>

    <div v-else class="comments-list">
      <div
        v-for="comment in comments"
        :key="comment.publicUUID"
        class="comment"
      >
        <router-link
          :to="`/comments/${comment.publicUUID}/${comment.slug}`"
          class="comment-link"
        >
          <div class="comment-content">
            <p class="comment-text">{{ comment.text }}</p>
            <div class="comment-meta">
              <!-- Remove Emoji here; rely on CSS to add emoji -->
              <span class="comment-likes">{{ comment.likes }}</span>
              <span class="comment-replies"
                >{{ comment.children.length }} replies</span
              >
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>

  <div v-else>
    <p>Loading post...</p>
  </div>
</template>

<script>
import "../styles/navbarstyles.css";
import config from "@/config";
import { useHead } from "@vueuse/head";

import LinkPreview from "../components/LinkPreview.vue";

export default {
  name: "PostView",
  data() {
    return {
      post: null,
      comments: [],
      error: null,
    };
  },
  methods: {
    fetchPost() {
      const { post_public_id, post_slug } = this.$route.params;
      this.post = null;
      this.$axios
        .get(`${config.apiBaseUrl}/posts/${post_public_id}/${post_slug || ""}`)
        .then((response) => {
          this.post = response.data;
          console.log("Fetched post:", this.post);

          if (this.post && this.post.id) {
            // Set meta tags after post is loaded
            this.setMetaTags();
            // Load comments after post is loaded
            this.fetchComments(this.post.id);
          } else {
            console.error("Post data is missing 'id' property:", this.post);
            this.error = "Invalid post data received.";
          }
        })
        .catch((error) => {
          console.error("Error fetching post:", error);
          this.error = "Failed to load post.";
        });
    },
    fetchComments(postID) {
      this.comments = [];
      this.$axios
        .get(`${config.apiBaseUrl}/comments?postID=${postID}`)
        .then((response) => {
          this.comments = response.data;
        })
        .catch((error) => {
          console.error("Error fetching comments:", error);
          this.error = "Failed to load comments.";
        });
    },
    downloadApp() {
      window.open(
        "https://apps.apple.com/us/app/breakup-buddy-heal-heartbreak/id1667956640",
        "_blank"
      );
    },
    setMetaTags() {
      if (this.post) {
        useHead({
          title: this.post.title,
          meta: [
            {
              name: "description",
              content: this.post.text.slice(0, 160),
              key: "description",
            },
            {
              property: "og:title",
              content: this.post.title,
              key: "og:title",
            },
            {
              property: "og:description",
              content: this.post.text.slice(0, 160),
              key: "og:description",
            },
            {
              property: "og:url",
              content: window.location.href,
              key: "og:url",
            },
          ],
        });
      }
    },
  },
  created() {
    this.fetchPost();
  },
  watch: {
    "$route.params.post_public_id": "fetchPost",
    "$route.params.post_slug": "fetchPost",
  },
  components: { LinkPreview },
};
</script>

<style scoped>
/* Main container with more side padding for narrower content */
.post-view-container {
  max-width: 750px; /* Reduced the max-width to make main content narrower */
  margin: 0 auto;
  padding: 20px 40px; /* Adjusted padding: 20px for top/bottom, 40px for sides */
}

/* Post content box - no changes here */
.post-content {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

/* Title and Post styling */
.post-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.post-text {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Post meta information */
.post-meta {
  font-size: 0.9rem;
  color: #999;
}

.post-likes::before {
  content: "👍";
  margin-right: 5px;
}

/* Tags associated with the post */
.post-tags {
  margin-bottom: 10px;
}

.tag-item {
  display: inline-block;
  background-color: #e0e0e0;
  color: #333;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 50px;
  font-size: 0.9rem;
}

/* Comments section */
.comments-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.comment {
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 15px;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
}

.comment:hover {
  background-color: #f7f7f7;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

.comment-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comment-text {
  font-size: 1rem;
  color: #444;
}

.comment-meta {
  font-size: 0.85rem;
  color: #888;
  display: flex;
  gap: 15px;
}

/* Insert emoji using pseudo-element `::before` */
.comment-likes::before {
  content: "👍";
  margin-right: 5px;
}

.comment-replies::before {
  content: "💬";
  margin-right: 5px;
}

/* No comments message */
.no-comments {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 20px;
  color: #777;
}

/* Responsive media queries for smaller devices */
@media (max-width: 768px) {
  .post-view-container {
    padding: 15px; /* Reduce side padding for smaller screens */
    max-width: 100%; /* Expand full width for narrow mobile screens */
  }

  .post-content,
  .comment {
    padding: 15px;
  }

  .post-title {
    font-size: 1.6rem;
  }

  .post-text {
    font-size: 1.1rem;
  }

  .comment-text {
    font-size: 0.95rem;
  }
}
</style>
